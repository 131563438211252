import React from "react";

export default function LoadingSpinner({ color = "blue"}) {
  if(color === "blue") {
    return (
        <div className="loading-spinner inline-child"></div>
    );
  }else{
    return (
        <div className="loading-spinner-white inline-child"></div>
    );
  }
}

