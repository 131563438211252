/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import Spinner from "./Spinner";


function BitrixLink() {
  //will keep track of all contacts linked in the current displayed message
  const [contactsInMesage, setContactsInMessage] = useState([]);
  const [suggestedContacts, setSuggestedContacts] = useState([]);
  const [linkedItems, setLinkedItems] = useState([]);
  const [suggestedLeadsAndDeals, setSuggestedLeadsAndDeals] = useState([]);
  const [messageSubject, setMessageSubject] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [emailsNotInBitrix, setEmailsNotInBitrix] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoadingIds, setLoadingIds] = useState([]);
  const [currentMessageId, setMessageId] = useState();

  const bitrixWebhook = Office.context.roamingSettings.get("bitrixWebhookUrl");
  const bitrixUserId = Office.context.roamingSettings.get("bitrixUserId");
  var bitrixUrl = "https://crm.cresco.ae/rest/" + bitrixUserId + "/" + bitrixWebhook + "/";


  useEffect(() => {
    //get bitrix webhook from URL
    const abortController = new AbortController();

    Office.onReady(() => {
      console.log("first load");
      getSuggestedContacts();
      // add event listener to mailbox item change event
      Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, getSuggestedContacts);
    });

    return () => {
      abortController.abort();
      // stop the query by aborting on the AbortController on unmount
    };
  }, []);

  useEffect(() => {
    console.log("contacts In mesage updated");
    getLeadDealSuggestions();
  }, [contactsInMesage]);

  useEffect(() => {
    console.log("linked items updated", linkedItems);
    console.log("suggested contacts", suggestedLeadsAndDeals);
    if (linkedItems.length > 0) {
      var filteredSuggestedContacts = suggestedContacts.filter(
        (contact) => !linkedItems.some((item) => item.OWNER_ID === contact.ID && item.OWNER_TYPE_ID == 3)
      );
      setSuggestedContacts(filteredSuggestedContacts);

      var filteredLeadsAndDeals = suggestedLeadsAndDeals.filter(
        (leadDeal) => !linkedItems.some((item) => item.OWNER_ID === leadDeal.ID && item.OWNER_TYPE_ID != 3)
      );
      setSuggestedLeadsAndDeals(filteredLeadsAndDeals);
    }
  }, [JSON.stringify(linkedItems)]);


const updateLoadingIds = useCallback(
    (newIds, removedIds) => {
      setLoadingIds((prevIds) => {
        // Combine the previous IDs with the new IDs, and remove any duplicates
        const updatedIds = [...new Set([...prevIds, ...newIds])];
  
        // Remove any IDs that need to be removed
        removedIds.forEach((id) => {
          const index = updatedIds.indexOf(id);
          if (index !== -1) {
            updatedIds.splice(index, 1);
          }
        });
  
        return updatedIds;
      });
    },
    [setLoadingIds]
  );

  const updateLinkedItems = useCallback((newItems, removedItemIds) => {
    setLinkedItems((prevItems) => {
      

      // Remove any items that need to be removed
      const updatedItems = prevItems.filter(item => !removedItemIds.includes(item.OWNER_ID));
      
      
      // Add the new items to the end of the array if they don't already exist
      newItems.forEach((newItem) => {
        if (!prevItems.some((prevItem) => prevItem.OWNER_ID === newItem.OWNER_ID)) {
          updatedItems.push(newItem);
        }
      });
   
  
      return updatedItems;
    });
  }, [setLinkedItems]);



  
  async function getSuggestedContacts() {
    //clear state values on message change
    setLinkedItems([]);
    setSuggestedLeadsAndDeals([]);
    setSuggestedContacts([]);
    setEmailsNotInBitrix([]);
    // setLoadingIds([]);
    const emailInfo = Office.context.mailbox.item;
    if (emailInfo) {

      var messageId = emailInfo.internetMessageId.replace("<", "").replace(">", "");
      setMessageId(messageId);
      //Get email ids of recipients and sender
      var emailArray = [];
      var senderEmail = emailInfo.sender.emailAddress;
      var cc = extractEmails(emailInfo.cc);
      var to = extractEmails(emailInfo.to);

      emailArray = emailArray.concat(senderEmail);
      emailArray = emailArray.concat(cc);
      emailArray = emailArray.concat(to);

      //GET SUGGESTED CONTACTS
      var params = {
        contact_emails: emailArray,
      };

      axios({
        method: "post",
        url: bitrixUrl + "crm.contact.bulklist",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: JSON.stringify(params),
      }).then(function (res) {
        var contactsResult = res.data.result;
        contactsResult.map(function (item) {
          item.LABEL = item.NAME + " " + item.LAST_NAME;
          return item;
        });

        //filter out email addresses that are not linked to contacts and type id is not 1 (employee)
      

        var emailsNotLinkedToContacts = emailArray.filter($email => !contactsResult.some($contact => $contact.EMAIL.toLowerCase() === $email.toLowerCase()) && $email.TYPE_ID != 1);
        setEmailsNotInBitrix(emailsNotLinkedToContacts);

        var nonEmployeeContacts = contactsResult.filter((contact) => contact.TYPE_ID != 1);

        if(nonEmployeeContacts.length > 0){
            setSuggestedContacts(nonEmployeeContacts);
            setContactsInMessage(nonEmployeeContacts);
        }else{
            //call manually since useffect wont be triggered
            getLinkedItems();
        }
        
      });
    }
  }

  async function getLeadDealSuggestions() {
    const uniqueContactIds = [...new Set(contactsInMesage.map((contact) => contact.ID))];
    var params = {
      contacts: uniqueContactIds,
    };

    axios({
      method: "post",
      url: bitrixUrl + "crm.contact.email.deal",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: JSON.stringify(params),
    }).then(function (res) {
      if (res.data.result) {
        res.data.result.map(function (item) {
          item.LABEL = item.TITLE;
          return item;
        });
        setSuggestedLeadsAndDeals(res.data.result);
        console.log("lead suggestions", res.data.result);
        getLinkedItems();
      }
    });
  }

  async function getLinkedItems() {
    const emailInfo = Office.context.mailbox.item;
    if (emailInfo) {
      //Get message Id
      var messageId = emailInfo.internetMessageId.replace("<", "").replace(">", "");

      //GET LINKED CRM ITEMS
      var params = {
        filter: {
          message_id: messageId,
        },
      };

      axios({
        method: "post",
        url: bitrixUrl + "crm.contact.email.get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: JSON.stringify(params),
      }).then(function (res) {
        if (res.data.result) {
          console.log("linked Items", res.data.result);
          //setLinkedItems(res.data.result);
          updateLinkedItems(res.data.result, [])
        }
      });
    }
  }

  async function linkItem(crmItem, type) {
     //get message info
    const emailInfo = Office.context.mailbox.item
    var messageId = emailInfo.internetMessageId.replace("<", "").replace(">", "");

    //combine crmItem id and message id for loading indicator
    const messageCrmId = crmItem.ID + "_" + messageId;

    //check if crmItem id is in showloadingids 
    //if it is, return and do not add to showloadingids to prevent double linking
    if (showLoadingIds.includes(messageCrmId)) {
      return;
    }

    emailInfo.body.getAsync("html", { asyncContext: "This is passed to the callback" }, function callback(result) {
    
      //Get CC Mail ids
      var ccList = emailInfo.cc.map(({ emailAddress }) => emailAddress).toString();

      //Format date for Bitrix
      var year = emailInfo.dateTimeCreated.getFullYear();
      var month = String(emailInfo.dateTimeCreated.getMonth() + 1).padStart(2, "0");
      var day = String(emailInfo.dateTimeCreated.getDate()).padStart(2, "0");

      var bitrixDate =
        day +
        "." +
        month +
        "." +
        year +
        " " +
        emailInfo.dateTimeCreated.getHours() +
        ":" +
        emailInfo.dateTimeCreated.getMinutes() +
        ":" +
        emailInfo.dateTimeCreated.getSeconds();

        var attachments = [];

        //Get attachment list
        emailInfo.attachments.forEach((attachment) => {
          var obj = {
            id: attachment.id,
            name: attachment.name,
            contentType: attachment.contentType,
          };

          attachments.push(obj);
        });

        var messageObj = {
          subject: emailInfo.subject,
          message: result.value,
          owner_id: crmItem.ID,
          owner_type: type,
          date: bitrixDate,
          from: emailInfo.sender.emailAddress,
          to: emailInfo.to[0].emailAddress,
          message_key: emailInfo.itemId,
          message_id: messageId,
          sender: emailInfo.sender.displayName,
          cc: ccList,
        };

        //email has attachments
        if (attachments.length > 0) {

          var form_data = new FormData();

          for (var key in messageObj) {
            form_data.append(key, messageObj[key]);
          }

          //create a promise for each attachment
          var promises = [];
          attachments.forEach((attachment) => {
            let promise = new Promise(function (resolve, reject) {
              emailInfo.getAttachmentContentAsync(attachment.id, { asyncContext: attachment }, function myCall(result) {
                result.asyncContext.content = result.value.content;
                resolve(result.asyncContext);
              });
            });

            promises.push(promise);
          });
        
          //add item id to loading ids to show loading icon
          updateLoadingIds([messageCrmId],[])

          //wait for all promises to resolve
          Promise.all(promises).then(function (result) {
            form_data.append("attachments", JSON.stringify(result));
            axios({
              method: "post",
              url: bitrixUrl + "/crm.contact.email.link",
              headers: {
                "Content-Type": "multipart/form-data",
              },
              data: form_data,
            }).then(function (res) {
                console.log("linkResponse with attachment", res);
                addToLinkedItems(crmItem, type, messageCrmId);
            }).catch((error) => {
                console.log(error);
                updateLoadingIds([],[messageCrmId])
            });
          });
        } 
        //email has no attachments
        else {
        
          updateLoadingIds([messageCrmId],[])

          axios({
            method: "post",
            url: bitrixUrl + "/crm.contact.email.link",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            data: JSON.stringify(messageObj),
          }).then(function (res) {
            console.log("linkResponse no attachment", res);
            addToLinkedItems(crmItem, type,messageCrmId);
          }).catch((error) => {
            console.log(error);
            updateLoadingIds([],[messageCrmId])
        });;;
        }
    });



  }

  async function unlinkItem(crmItem) {
    const emailInfo = Office.context.mailbox.item;
    var messageId = emailInfo.internetMessageId.replace("<", "").replace(">", "");

    var parameter = {
      filter: {
        message_id: messageId,
        owner_id: crmItem.OWNER_ID,
        owner_type: crmItem.OWNER_TYPE_ID,
      },
    };

    axios({
      method: "post",
      url: bitrixUrl + "/crm.contact.email.delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: JSON.stringify(parameter),
    }).then(function (res) {
      if (res.data.result == true) {
        var updatedLinkedItems = linkedItems.filter((item) => item.ID != crmItem.ID);
        //setLinkedItems(updatedLinkedItems);
        updateLinkedItems([],[crmItem.OWNER_ID])
      }

      console.log("unlink", res);
    });
  }

  function addToLinkedItems(linkItem, type, messageCrmId) {

    //Check if user has navigated to another email
    //Get the message id of the email that is currently open
    const emailInfo = Office.context.mailbox.item
    var updatedMessageId = emailInfo.internetMessageId.replace("<", "").replace(">", "");

    var newLinkedItem = {
      OWNER_ID: linkItem.ID,
      OWNER_TYPE_ID: type,
      LABEL: linkItem.LABEL,
    };

    //remove loading icon for the linked item
    updateLoadingIds([],[messageCrmId])
  
    //add linked item to the list only if the user has not navigated to another email
    //currentMessageId is the message id of the email that was open when the email was linked
    if(currentMessageId == updatedMessageId){
       updateLinkedItems([newLinkedItem],[])
    }
  }

  function extractEmails(emailArray) {
    var arrayList = [];
    emailArray.forEach(function (arrayItem) {
      arrayList.push(arrayItem.emailAddress);
    });
    return arrayList;
  }

  function renderSuggestLeadsAndDeals(leadDeal, index) {
    const messageCrmId = leadDeal.ID + "_" + currentMessageId;
    if (leadDeal.TYPE == 1) {
      return (
        <li onClick={() => linkItem(leadDeal, leadDeal.TYPE)} key={index} className="suggested-item ">
          <span className="item-type lead"> L </span>
          <span> {leadDeal.LABEL} </span>
          {showLoadingIds.includes(messageCrmId) && <Spinner/>}
        </li>
      );
    } else {
      return (
        <li onClick={() => linkItem(leadDeal, leadDeal.TYPE)} key={index} className="suggested-item ">
          <span className="item-type deal"> D </span>
          <span> {leadDeal.LABEL} </span>
          {showLoadingIds.includes(messageCrmId) && <Spinner/>}
        </li>
      );
    }
  }

  function renderLinkedItems(linked, index) {
    const messageCrmId = linked.OWNER_ID + "_" + currentMessageId;
    if (linked.OWNER_TYPE_ID == 1) {
      return (
        <li onClick={() => unlinkItem(linked)} key={index} className="item-selected">
          <span className="item-type-selected"> L </span>
          <span> {linked.LABEL} </span>
          {showLoadingIds.includes(messageCrmId) && <Spinner color={'white'}/>}
        </li>
      );
    } else if (linked.OWNER_TYPE_ID == 2) {
      return (
        <li onClick={() => unlinkItem(linked)} key={index} className="item-selected">
          <span className="item-type-selected"> D </span>
          <span> {linked.LABEL} </span>
          {showLoadingIds.includes(messageCrmId) && <Spinner color={'white'}/>}
        </li>
        
      );
    } else {
      return (
        <li onClick={() => unlinkItem(linked)} key={index} className="item-selected">
          <span className="item-type-selected"> C </span>
          <span> {linked.LABEL} </span>
          {showLoadingIds.includes(messageCrmId) && <Spinner color={'white'}/>}
          
        </li>
      );
    }
  }

  function handleSelected(selectedItem) {
      selectedItem.LABEL = selectedItem.TITLE;

      const emailInfo = Office.context.mailbox.item;
      var messageId = emailInfo.internetMessageId.replace("<", "").replace(">", "");
      //combine crmItem id and message id for loading indicator
      const messageCrmId = selectedItem.ID + "_" + messageId;

      addToLinkedItems(selectedItem,  selectedItem.TYPE, messageCrmId);

    

      linkItem(selectedItem, selectedItem.TYPE);
    //   setSelectedValue(null)
  }

  function loadOptions(inputValue){

    if (inputValue.length > 4) {
        var params = {
            search: inputValue
        }; 

        return axios({
            method: "post",
            url: bitrixUrl + "crm.contact.search",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            data: JSON.stringify(params),
          }).then(function (res) {
                return res.data.result;
          });
  }else{
         return Promise.resolve(); 
    }

  
  }

  function renderWarning(){
      if(emailsNotInBitrix.length > 0){
          return (
              <div className="warning">
                  <p>The following email addresses are not in Bitrix:</p>
                  <ul>
                  {emailsNotInBitrix.map((contact, index) => (
                      <li  key={index} className="test">
                        {contact}
                      </li>
                    ))}
                  </ul>
              </div>
          )
      }
  }


  return (
    <div>
        <AsyncSelect
          value={selectedValue}
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions
          onChange={handleSelected}
          getOptionLabel={e => e.TITLE}
          getOptionValue={e => e.ID}
          escapeClearsValue={true}
          placeholder="Search Bitrix"
          formatOptionLabel={item => {
            if(item.TYPE == 1){
                return (<div>
                    <span className="item-type lead search-option">L</span>
                    <span>{item.TITLE}</span>
                  </div>)
            }
            else if(item.TYPE == 2){
                return (<div>
                    <span className="item-type deal search-option">D</span>
                    <span>{item.TITLE}</span>
                  </div>)
            }
            else if(item.TYPE == 3){
                return (<div>
                    <span className="item-type search-option">C</span>
                    <span>{item.TITLE}</span>
                  </div>)
            }
          }}
        />
      <p>
        <label>{messageSubject}</label>
      </p>
        {renderWarning()}
      <p>
      
        <label>Linked Items in Bitrix</label>
      </p>
      <ul className="suggested-contacts-list">
        {linkedItems.map((linked, index) => renderLinkedItems(linked, index))}
      </ul>
      <p>
        <label>Suggested Contacts</label>
      </p>
      <ul className="suggested-contacts-list">
        {
        suggestedContacts.map((contact, index) => (
          <li onClick={() => linkItem(contact, 3)} key={index} className="suggested-item">
            <div className="inline-child">{contact.LABEL}</div>
            {showLoadingIds.includes(contact.ID + "_" + currentMessageId) && <Spinner/>}
            
          </li>
        ))
        }
      </ul>
      <p>
        <label>Suggested Leads & Deals</label>
      </p>
      <ul className="suggested-contacts-list">
        {suggestedLeadsAndDeals.map((leadDeal, index) => renderSuggestLeadsAndDeals(leadDeal, index))}
      </ul>
    </div>
  );
}

export default BitrixLink;
